import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <div className="addbg2">
          <div className="wrapper">
            <header
              style={{
                height: "120px"
              }}
            >
              <div
                style={{
                  height: "67px"
                }}
              >
                <h1 className="logo">
                  <a href="/" />
                </h1>
                <nav>
                  <a href="/site/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/site/o-neutrogenie/kim-jestesmy/" className="link">
                    O Neutrogenie
                  </a>
                  <span>| </span>
                  <a href="/site/bad-piekna/" className="link">
                    Bądź piękna
                  </a>
                  <span>| </span>
                  <a href="/site/see-whats-possible/" className="link">
                    See What's Possible
                  </a>
                  <span>| </span>
                  <a href="/site/skora-z-niedoskonalosciami/" className="link">
                    Skóra z niedoskonałościami
                  </a>
                  <span>| </span>
                </nav>
              </div>
              <div
                style={{
                  "padding-left": "185px",
                  height: "53px"
                }}
              >
                <nav
                  className="second"
                  style={{
                    float: "none"
                  }}
                >
                  <a href="/site/hydroboost/" className="link">
                    Hydro Boost
                  </a>
                  <span>| </span>
                  <a href="/site/skin-detox/" className="link">
                    Skin Detox
                  </a>
                  <span>| </span>
                  <a href="/site/cellular-boost/" className="link">
                    Cellular Boost
                  </a>
                  <span />
                </nav>
              </div>
            </header>
            <div className="content">
              <section className="main">
                <div className="text full">
                  <div className="law">
                    <div className="WordSection1">
                      <h1>
                        <strong>ZASADY KORZYSTANIA Z PLIKÓW COOKIE</strong>
                      </h1>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>
                      Celem niniejszych Zasad korzystania z plików cookie jest prezentacja zasad stosowanych w firmie w kontekście gromadzenia informacji za pośrednictwem plików cookie i innych technologii śledzenia (np. plików gif, elementów nawigacyjnych web beacon itd.).
                      </p>
                      <p>
                      Do funkcjonalności, w których wykorzystujemy te technologie, mogą się zaliczać:
                       <br />- Ułatwianie użytkownikom nawigacji,
                        <br />- Ułatwianie użytkownikom dokonania rejestracji udziału w wydarzeniach, logowania się i przesyłania opinii,
                        <br />- Analiza sposobu korzystania z naszych produktów, usług lub aplikacji,
                        <br />- Wsparcie działań promocyjno-marketingowych firmy (m.in. reklama behawioralna),
                        <br />- Proponowanie treści niezależnych podmiotów (np. treści w serwisach społecznościowych)
                      </p>
                      <p>&nbsp;</p>
                      <p>
                      Poniżej znajduje się szczegółowa lista wykorzystywanych przez nas plików cookie wraz z opisem. Pliki cookie dzielimy na następujące kategorie:
                       <br />- Bezwzględnie konieczne
                        <br />- Związane z wydajnością
                        <br />- Funkcjonalne
                        <br />- Ukierunkowujące
                      </p>
                      <p>
                      Z wyjątkiem przypadków dozwolonych przez obowiązujące prawo, pliki cookie są
                       umieszczane na dysku użytkownika dopiero po uzyskaniu jego zgody za pośrednictwem
                        banera informującego o plikach cookie albo poprzez centrum preferencji.
                       Ustawienia plików cookie można w każdej chwili zmienić dla poszczególnych 
                       kategorii plików cookie (z wyjątkiem plików bezwzględnie koniecznych, niezbędnych do
                        prawidłowego funkcjonowania witryny),
                       klikając przycisk „Ustawienia plików cookie” (cookie settings) poniżej:&nbsp;
                      </p>
                      <p>&nbsp;</p>
                      <p>
                      <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                      Ustawienia plików Cookie
                              </button>
                              </p>
                        <div id="ot-sdk-cookie-policy">&nbsp;</div>          
                    
                      <p>&nbsp;</p>
                      <p>
                        <strong>Linki do innych witryn</strong>
                      </p>
                      <p>
                      Witryna może zawierać linki i odnośniki do innych witryn. Należy mieć na uwadze, że nie kontrolujemy plików cookie ani technologii śledzenia stosowanych w innych witrynach i niniejsze Zasady korzystania z plików cookie nie mają do tych witryn zastosowania.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>Jak się z nami skontaktować?</strong>
                      </p>
                      <p>
                      W razie pytań, uwag lub zastrzeżeń dotyczących niniejszych Zasad korzystania z plików cookie lub procedur dotyczących informacji, które obowiązują w tej witrynie, należy skorzystać z danych kontaktowych podanych w naszej Polityce prywatności.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>
                        Zmiany Zasad korzystania z plików cookie
                        </strong>
                      </p>
                      <p>
                      W razie zmiany Zasad korzystania z plików cookie zmieniona wersja zostanie opublikowana w tej witrynie. Data ostatniej aktualizacji Zasad korzystania z plików cookie:
                       14-05-2021
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div id="social_id_10"></div>
          </div>
        </div>
        <footer>
          <div className="wrapper">
            <nav>
              <a href="/site/" className="logo2" />
              <a href="/site/nota-prawna/">Nota Prawna</a>
              <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
               <a href="/site/cookies/">Polityka cookies</a>
              <a href="/site/kontakt/">Zachęcamy do kontaktu</a>
                           <span>© Johnson &amp; Johnson Poland Sp. z o.o. 2013 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
            <p>
              Strona zarządzana jest przez Johnson &amp; Johnson Poland Sp. z o.
              o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
              Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
              Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
              113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
              Johnson &amp; Johnson Poland Sp. z o. o. ponosi wyłączną
              odpowiedzialność za treści zawarte na niniejszej stronie.
              <br /> Strona jest przeznaczona dla użytkowników z Polski.
              Ostatnia aktualizacja: 25/10/2013
              <br />{" "}
            </p>{" "}
            <p>
              <span>
                Strona została zoptymalizowana pod kątem przeglądarek: Internet
                Explorer 7 i wyższych, Mozilla Firefox, Google Chrome, Opera,
                Safari
              </span>{" "}
              <span className="right">
                Zalecana rozdzielczość: 1024 x 768 pikseli
              </span>{" "}
            </p>
          </div>
        </footer>
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
          }}
        />
        
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
          }}
        />
      </div>
    );
  }
}

export default Page;
